/*!
 * @overview response.js
 */
(function ($, undefined) {
    var cache = [];
    $(function () {
        return $.extend({
            response: function (response, callback, failURL, failCallback) {
                if (typeof response == 'string' && callback) {
                    callback(response);
                    return;
                }
                if (response.redirect != undefined) {
                    failURL = response.redirect;
                }
                switch (response.status) {
                    case 'ok':
                        if (callback) {
                            callback(response);
                        }
                        break;
                    case 'nok':
                        $.popup({
                            customContent: response.reason,
                            width: 600,
                            onClose: function () {
                                if (failURL !== undefined) {
                                    History.pushState({}, $(document).attr('title'), BASEURL + failURL);
                                }
                                if (failCallback) failCallback();
                                return true;
                            }
                        });
                        break;
                    default:
                        if (callback) {
                            callback(response);
                        }
                }
            }
        });
    });
    module.exports = $;
})($);