/*!
 * @overview trans.js
 */
$.extend({
    trans: function (key, parameters = null, domain = null, locale = null) {
        if ($.isArray(key)) {
            var translations = [];
            $.each(key, function (index, key) {
                if (cache[key] === undefined) {
                    if (parameters !== undefined && parameters !== null) {
                        parameters = $.isArray(parameters[0]) ? parameters[index] : parameters;
                    }
                    domain = $.isArray(domain) ? domain[index] : domain;
                    locale = $.isArray(locale) ? locale[index] : locale;
                    translations.push({
                        key: key,
                        parameters: parameters,
                        domain: domain,
                        locale: locale,
                    });
                }
            });
            if (translations.length > 0) {
                $.ajax({
                    type: "POST",
                    url: BASEURL + 'translate',
                    data: {
                        action: 'translateMultiple',
                        translations: translations
                    },
                    async: false,
                    success: function (response) {
                        $.each(response.result, function (key, value) {
                            cache[key] = value;
                        });
                    }
                });
            }
        } else {
            if (cache[key] === undefined) {
                $.ajax({
                    type: "POST",
                    url: BASEURL + 'translate',
                    data: {
                        action: 'translate',
                        key: key,
                        parameters: parameters,
                        domain: domain,
                        locale: locale,
                    },
                    async: false,
                    success: function (response) {
                        cache[key] = response.content;
                    }
                });
            }
            var result = cache[key];
            if (arguments.length > 1) {
                for (var i = 1; i < arguments.length; i++) {
                    result = result.replace('%' + i + '%', arguments[i]);
                }
            }

            return result;
        }
    }
});