/*!
 * @overview popup.js
 */
(function ($, window, document, undefined) {
    $(() => {
        let dialog;
        let mask;
        let settings;
        let alreadyClicked;
        let autoCloseTimeout = null;
        const showMask = function () {
            if (mask !== undefined) {
                mask.stop().remove();
            }
            mask = $('<div>').attr('id', 'mask');
            mask.stop(true, true);
            mask.css('opacity', 0);
            $(settings.container).append(mask);

            mask.animate({
                opacity: 0.8,
            }, 300, 'swing',
            () => {
                if (settings.closePopupOnMaskClick) {
                    mask.click(() => {
                        close();
                    });
                }
            });
        };

        const removeMask = function () {
            if (settings.fastClose) {
                mask.remove();
            } else {
                mask.animate({
                    opacity: 0,
                }, 300, 'swing', () => {
                    mask.remove();
                });
            }
        };

        const removePopup = function () {
            dialog = $(settings.container).find('.popup').last();
            dialog.unbind();
            if (settings.fastClose) {
                dialog.remove();
            } else {
                dialog.fadeOut(300).remove();
            }
        };

        var close = function () {
            if (autoCloseTimeout) {
                clearInterval(autoCloseTimeout);
                autoCloseTimeout = null;
            }
            if (settings.mandatoryButtons && !alreadyClicked) return;
            if (!settings.onClose()) return;
            if (autoCloseTimeout !== null) {
                clearTimeout(this.autoCloseTimeout);
            }
            autoCloseTimeout = null;
            removePopup();
            removeMask();
            $(document).unbind('closePopupFast');
            $(document).unbind('closePopup');
            $(document).unbind('keydown');
            settings.afterClose();
        };

        const showPopup = function () {
            alreadyClicked = false;
            dialog = $('<div>').addClass('popup' + (settings.popupClass !== undefined ? ' ' + settings.popupClass : ''));
            if (settings.headline) {
                dialog.append($('<div>').addClass('headline').text(settings.headline));
            }
            dialog.append($('<div>').addClass('content').text(settings.text));
            if (settings.textLong.length > 0) dialog.append($('<span>').addClass('text_switch').text('mehr erfahren'));
            var buttons = $('<div>').addClass('buttons');
            if (settings.buttonContainerClass) {
                buttons.addClass(settings.buttonContainerClass);
            }
            dialog.append(buttons);
            if (settings.customContent !== undefined) {
                $('.content', dialog).html(settings.customContent);
            }
            if (settings.customButtons !== null) {
                if (settings.customButtons.length > 0) {
                    $.each(settings.customButtons, (index, button) => {
                        const b = $('<button>').text(button.title);
                        if (button.isDefault || button.executeOnEnter) {
                            b.addClass('default');
                        }
                        if (button.class) {
                            b.addClass(button.class);
                        }
                        if (button.id) {
                            b.attr('id', button.id);
                        }
                        if (settings.buttonClass) b.addClass(settings.buttonClass);
                        b.click(function () {
                            if (button.unbindAfterClick === undefined || button.unbindAfterClick) {
                                $(this).unbind();
                            }
                            alreadyClicked = true;
                            if (button.buttonFunction !== undefined) {
                                const result = button.buttonFunction();
                                if (result !== false) {
                                    close();
                                }
                            } else {
                                close();
                            }
                        });
                        $('.buttons', dialog).append(b);
                    });
                } else {
                    $('.buttons', dialog).hide();
                }
            } else if (settings.closeButton) {
                const dialogButtons = $('.buttons', dialog);
                dialogButtons.append($('<button>').text(settings.buttonText));
                if (settings.buttonClass) $('button', dialogButtons).addClass(settings.buttonClass);
                $('button', dialogButtons).click(function () {
                    alreadyClicked = true;
                    $(this).unbind();
                    close();
                });
            }
            $('.text_switch', dialog).click(function (e) {
                $('.content', dialog).html(settings.textLong);
                $(this).remove();
                dialog.css('top', ((settings.top ? settings.top : (($(window).height() - dialog.height()) / 2))) + 'px');
            });
            $(document).on('closePopup', () => {
                close();
            });
            $(document).on('closePopupFast', () => {
                $(document).unbind('closePopupFast');
                $(document).unbind('closePopup');
                $(document).unbind('keydown');
                dialog = $(settings.container).find('.popup').last();
                dialog.unbind();
                dialog.remove();
                mask.remove();
            });
            $(document).on('keydown', (e) => {
                if (e.keyCode === 27) close();
            });
            $(dialog).on('keyup', (e) => {
                switch (e.keyCode) {
                    case 13:// enter
                        if (settings.closeOnEnter) close();
                        if (settings.customButtons) {
                            $.each(settings.customButtons, (index, button) => {
                                if (button.buttonFunction && button.executeOnEnter) {
                                    const result = button.buttonFunction();
                                    if (result !== false) {
                                        close();
                                    }
                                    e.stopPropagation();
                                    return false;
                                }
                            });
                        }
                        break;
                    case 39:// right
                        var buttons = dialog.find('.buttons button');
                        $.each(buttons, (index, button) => {
                            if ($(button).is(':focus')) {
                                if (++index === buttons.length) index = 0;
                                buttons[index].focus();
                                return false;
                            }
                        });
                        break;
                    case 37:// left
                        var buttons = dialog.find('.buttons button');
                        $.each(buttons, (index, button) => {
                            if ($(button).is(':focus')) {
                                if (--index === -1) index = buttons.length - 1;
                                buttons[index].focus();
                                return false;
                            }
                        });
                        break;
                }
            });
            $('.content', dialog).css('min-height', settings.minHeight + 'px')
                .css('text-align', settings.textAlign);
            dialog.css('width', settings.width + 'px')
                .css('left', ($(settings.container).width() - settings.width) / 2 + 'px');
            $(settings.container).append(dialog);
            var buttons = $('.buttons button', dialog);
            const defaultButton = buttons.filter('.default');
            if (defaultButton.length > 0) {
                defaultButton.focus();
            } else {
                buttons.first().focus();
            }
            dialog.css('top', ((settings.top ? settings.top : (($(settings.container === 'body' ? window : settings.container).height() - dialog.height()) / 2))) + 'px');
            if (settings.autoClose > 0) {
                autoCloseTimeout = setTimeout(() => {
                    if (!settings.onClose()) return;
                    removePopup();
                    removeMask();
                }, settings.autoClose);
            }
            settings.onOpen();
        };

        return $.extend({
            popup(options) {
                settings = $.extend({
                    container: 'body',
                    headline: false,
                    text: '',
                    textLong: '',
                    width: 200,
                    customButtons: null,
                    mandatoryButtons: false,
                    minHeight: 0,
                    textAlign: 'center',
                    autoClose: 0,
                    buttonText: 'Ok',
                    closeButton: true,
                    closeOnEnter: false,
                    fastClose: false,
                    closePopupOnMaskClick: true,
                    onClose() {
                        return true;
                    },
                    afterClose() {
                    },
                    onOpen() {
                    },
                    beforeOpen(callback) {
                        if (callback !== undefined) {
                            callback();
                        }
                    },
                }, options);
                settings.beforeOpen(() => {
                    showMask();
                    showPopup();
                });
                this.resize = function () {
                    dialog.css('top', ((settings.top ? settings.top : (($(window).height() - dialog.height()) / 2))) + 'px').css('left', ($(settings.container).width() - settings.width) / 2 + 'px');
                };
                this.updateContent = function (customContent) {
                    settings.customContent = customContent;
                    $('.content', dialog).html(settings.customContent);
                };
                this.fastClose = function (dontRemoveMask) {
                    if (autoCloseTimeout) {
                        clearInterval(autoCloseTimeout);
                        autoCloseTimeout = null;
                    }
                    $(document).unbind('closePopupFast');
                    $(document).unbind('closePopup');
                    $(document).unbind('keydown');
                    const dialog = $('.popup').last();
                    dialog.unbind();
                    dialog.remove();
                    if (dontRemoveMask === undefined || dontRemoveMask === false) {
                        mask.remove();
                    }
                };
                this.close = function (callback) {
                    close();

                    if (callback !== undefined) {
                        if (settings.fastClose) {
                            callback();
                        } else {
                            setTimeout(() => {
                                callback();
                            }, 400);
                        }
                    }
                };
                return this;
            },
        });
    });
    module.exports = $;
}($, window, document));
