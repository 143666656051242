/*!
 * @overview Loading.js
 */
(function ($, window, document, undefined) {
    var Loading = function () {
        this.init()
    };

    Loading.prototype = {
        init: function () {
            var self = this;
            self.loadingDiv = $('<div>').attr('id', 'loading');
        },

        dispose: function () {
            $(document).unbind('click.Loading');
        },

        initClickHandlers: function () {
            $(document).on('click.Loading', '#loading', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });
        },

        start: function () {
            var self = this;
            $('body').append(self.loadingDiv);
        },

        stop: function () {
            var self = this;
            self.loadingDiv.remove();
        }
    };

    module.exports = Loading;
})(jQuery, window, document);