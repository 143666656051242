import * as Noty from './modules/noty';
import {Symfony, Cookie} from './modules/Symfony';

import 'sticky-kit/dist/sticky-kit.min';
import '@ctrlmaniac/simpler-sidebar';
import 'slick-carousel';
import 'lazysizes';

import '../js/modules/Loading';
import '../js/modules/popup';
import '../js/modules/response';
import '../js/modules/tooltip';
import '../js/modules/trans';
import {CartFunctions} from "./Common";

declare const getDiscount;
declare const flyoutURL;
declare const window: any;

jQuery.event.special.touchstart = {
    setup(_, ns, handle) {
        this.addEventListener('touchstart', handle as any, {passive: !ns.includes('noPreventDefault')});
    },
};
jQuery.event.special.touchmove = {
    setup(_, ns, handle) {
        this.addEventListener('touchmove', handle as any, {passive: !ns.includes('noPreventDefault')});
    },
};
jQuery.event.special.wheel = {
    setup(_, ns, handle) {
        this.addEventListener('wheel', handle as any, {passive: true});
    },
};
jQuery.event.special.mousewheel = {
    setup(_, ns, handle) {
        this.addEventListener('mousewheel', handle as any, {passive: true});
    },
};

class Main {
    private popup: Popup.Functions;

    private tooltip;

    private timeout: any;

    constructor() {
        const self = this;
        this.initEventHandlers();
        this.initErrorNotifier();
        this.initSidebar();
        this.initLanguagePicker();
        this.initSearch();
        this.initSlider();
        this.initBannerCountdown();

        // info: #1620 - Rabatt deaktivieren
        /* if (getDiscount) {
            this.openIncentiveDiscountPopup();
        } */
        /*
                if (showFlyout) {
                    setTimeout(function () {
                        self.openFlyout()
                    }, 30000);
                }
        */
    }

    private initEventHandlers() {
        const self: Main = this;
        const sidebar = $('#sidebar');

        $( document ).ready(function() {
            if ($( ".page-accessories #category > div" ).text().length) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#category").offset().top
                }, 300);
            }
            if ($( ".page-accessories #subcategory > div" ).text().length) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#subcategory").offset().top
                }, 300);
            }
            if ($( ".page-accessories #products > div" ).text().length) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#products").offset().top
                }, 300);
            }
        });

        $(document).on('click', '.content > .fa-chevron-down', function (e) {
            const elem = $(this).parent().children('.popup-tilt-protection-form');
            const arrowUp = $('.content > .fa-chevron-up');
            $(this).toggle();
            elem.animate({scrollTop: elem.prop('scrollHeight')}, 500);
            arrowUp.toggle();
        });

        $(document).on('click', '.content > .fa-chevron-up', function (e) {
            const elem = $(this).parent().children('.popup-tilt-protection-form');
            const arrowDn = $('.content > .fa-chevron-down');
            $(this).toggle();
            elem.animate({scrollTop: 0}, 500);
            arrowDn.toggle();
        });

        /**
         * Toggle footer navigation in mobile mode
         */
        $(document).on('pointerdown', 'footer .main-menu-link.mobile', function (e) {
            e.preventDefault();
            $(this).next('ul').slideToggle();
        });

        $(document).on('click', '#jsRun', (e) => {
            $.post('/cmd', {cmd: true}).then((response) => {
                $('#jsOutput').append($(response.output));
            });
        });

        sidebar.on('click', '.jsMainLink', function (e) {
            e.preventDefault();
            self.toggleSidebarLink($(this));
        });

        sidebar.on('click', '.jsSubMenuHeadline', function () {
            self.toggleSidebarLink($(this), true);
        });

        $(document).on('submit', 'form[name="catalog_request"]', function (e) {
            e.preventDefault();
            Symfony.postForm(this, (response) => {
                $.response(response, (response) => {
                    if (response.redirect) {
                        window.location.href = response.redirect;
                    } else {
                        $(this).trigger('reset');
                        self.popup.fastClose();
                        new Noty({
                            text: $.trans(response.message),
                            type: 'success',
                            timeout: 5000,
                        }).show();
                    }
                });
            });
        });

        $(document).on('mouseenter', '.jsToggleDropdown', (e) => {
            $('.jsDropdownMenu').fadeIn();
        });

        $(document).on('mouseleave', '.jsToggleDropdown', (e) => {
            const $dropdownMenu = $('.jsDropdownMenu');
            if (e.relatedTarget !== $dropdownMenu[0]) $dropdownMenu.fadeOut();
        });

        $(document).on('click', '#jsSearchContainer', function (e) {
            const container = e.target.className;
            if (container == 'search-container') {
                $(this).hide();
            }
        });

        $(document).on('click', '#submitSearch', (e) => {
            e.preventDefault();
            const searchInput = $(document).find('#jsSearchContainer').find('#search_form_search');
            const form = $(document).find('#jsSearchContainer').find('form[name="search_form"]');
            if (searchInput.val() != '') {
                form.trigger('submit');
            }
        });

        $(document).on('click', '.jsSearch', (e) => {
            e.preventDefault();
            const search = $(document).find('#jsSearchContainer');
            if (search.is(':hidden')) {
                search.show();
            } else {
                search.hide();
            }
        });

        $(document).on('mouseleave', '.jsDropdownMenu', (e) => {
            if (e.target !== $('.jsToggleDropdown')[0]) $('.jsDropdownMenu').fadeOut();
        });

        $(document).on('submit', '#jsPopupContactForm', function (e) {
            e.preventDefault();
            Symfony.postForm(this, (response) => {
                $(this).trigger('reset');
                self.popup.fastClose();
                new Noty({
                    text: $.trans(response.message),
                    type: 'success',
                    timeout: 5000,
                }).show();
            });
        });

        $(document).on('submit', 'form[name="newsletter"]', function (e) {
            e.preventDefault();
            Symfony.postForm(this, (response) => {
                $.response(response, (response) => {
                    $(this).trigger('reset');
                    self.popup.fastClose();
                    new Noty({
                        text: $.trans(response.message),
                        type: 'success',
                        timeout: 5000,
                    }).show();
                });
            });
        });

        $(document).on('click', '.jsRequestCatalog', function (e) {
            const url = $(this).data('url');
            self.showCatalogForm(url);
        });

        $(document).on('click', '.jsRequestNewsletter', function (e) {
            const url = $(this).data('url');
            self.showNewsletterForm(url);
        });

        $('#jsExitTemplateMode').on('click', function (e: Event) {
            $.post($(this).data('url'), {action: 'doneEditing'}).then((r) => {
                window.location.href = r.redirect;
            });
        });

        $(document).on('click', '#jsEnquireNow', function (e: Event) {
            e.preventDefault();
            const url = $(this).data('url');
            self.showEnquireNowForm(url);
        });

        $(document).on('click', '.jsCloseDiscountPopup', () => {
            Cookie.setCookie('disableDiscountPopup', true, 0.5);
            this.popup.fastClose();
        });

        $(document).on('click', '.jsGoToCart', () => {
            $.post(window.location.origin + '/redirectToCartWithIncentiveDiscount', (response) => {
                window.location.href = response.url;
            });
        });

        $(document).on('click', '.to-top', (e) => {
            $('html, body').animate({scrollTop: 0}, '50');
        });

        $('.jsMobileProductFilter .jsSubmitButton').on('click', (e) => {
            $('.jsMobileProductFilter').slideToggle();
        });

        $('.search-button').on('click', (e) => {
            e.preventDefault();
            const search = String($('#mobile_search_search').val());
            window.location.href = '/suche/' + encodeURIComponent(search);
            return false;
        });

        $('.openChat').on('click', (e) => {
            if (window.HubSpotConversations) {
                window.HubSpotConversations.widget.open();
            }
        });

        $('.jsCloseTooltip').on('click', (e) => {
            $('.jsToCartOverlay').hide();
        });

        $('.jsToCartOverlay').on('click', function (e) {
            $(this).hide();
        });

        $('.jsBlackFridayFlyout').on('click', function (e) {
            $(this).hide();
        });

        $('.jsMondu').on('click', function (e) {
            $(this).children('div').children('ul').slideToggle();
        });

        $('.jsOpenText').on('click', function (e) {
            e.preventDefault();
            if ($(this).html() == 'Mehr lesen...') {
                $(this).html('Weniger lesen...');
            } else if ($(this).html() == 'Weniger lesen...') {
                $(this).html('Mehr lesen...');
            } else if ($(this).html() == 'Meer lezen...') {
                $(this).html('Lees minder...');
            } else {
                $(this).html('Meer lezen...');
            }
            $(this).prev('div').toggleClass('opened');
        });

        $('.jsCloseConfiguratorSidebar').on('click', (e) => {
            e.preventDefault();
            $('#configuratorQuickstartMenu').trigger('click');
        });

        $('#configuratorMenu.page-configurator').on('click', function (e) {
            $(this).toggleClass('active');
        });

        $(document).on('click', '.jsSelectSurfaceColor', function (e) {
            const radio = $(this);
            if (radio.hasClass('active')) return;

            const parent = radio.parent();
            const grandParent = parent.parent();
            const select = grandParent.parent().children('select');
            const optionId = radio.attr('data-id');
            select.val(optionId).trigger('change');

            grandParent.children('div').each(function () {
                $(this).children('.jsSelectSurfaceColor').each(function () {
                    $(this).removeClass('active');
                });
            });
            radio.addClass('active');
        });

        $('.configuration .headline').on('click', function () {
            $(this).children('.fal').toggleClass('rotate90');
        });

        $(document).on('click', '.jsSelectFrameColor', function (e) {
            const radio = $(this);
            if (radio.hasClass('active')) return;

            const select = $('#form_color');
            const optionId = radio.attr('data-id');
            select.val(optionId).trigger('change');
            radio.addClass('active');
        });

        $(document).on('click', '.jsSelectFrameColor', function (e) {
            const radio = $(this);
            if (radio.hasClass('active')) return;

            radio.removeClass('active');
            const select = $('#form_color');
            const optionId = radio.attr('data-id');
            select.val(optionId).trigger('change');
            radio.addClass('active');
        });

        $('.configuration').on('click', '.jsGetAccessoryChildren', function (e) {
            e.preventDefault();
            e.stopPropagation();
            const uri = $(this).data('uri');
            $('#subcategory').empty();
            $('#products').empty();
            $.get('/getAccessoryChildren/' + uri, function (response) {
                $('#category').html(response);
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#category").offset().top
                }, 300);
            }, 'html').fail(function (error) {
                $.popup({text: $.trans('unexpected_error_has_occurred')});
            });
            return false;
        });

        $('#category').on('click', '.jsGetAccessoryGrandChildren', function (e) {
            e.preventDefault();
            e.stopPropagation();
            const uri = $(this).data('uri');
            $('#products').empty();
            $.get('/getAccessoryGrandChildren/' + uri, function (response) {
                $('#subcategory').html(response);
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#subcategory").offset().top
                }, 300);

            }, 'html').fail(function (error) {
                $.popup({text: $.trans('unexpected_error_has_occurred')});
            });
            return false;
        });

        $('#subcategory').on('click', '.jsGetAccessoryProductItems', function (e) {
            e.preventDefault();
            e.stopPropagation();
            const uri = $(this).data('uri');
            $.get('/getAccessoryProductItems/' + uri, function (response) {
                $('#products').html(response);
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#products").offset().top
                }, 300);
            }, 'html').fail(function (error) {
                $.popup({text: $.trans('unexpected_error_has_occurred')});
            });
            return false;
        });

        /**
         * Hide filter reset button if filters don't exist
         */
        const countFilters = $('#product_category_filter').children().length;
        if (countFilters === 1) {
            $('button.jsResetFilter').hide();
        }
    }

    private toggleSidebarLink(link, flyout: boolean = false) {
        const list = flyout ? link.parent().find('.jsSubMenu') : link.parents('li').find('.jsFlyout');

        if (!link.hasClass('active')) {
            link.addClass('active').find('.mobile-icon').first().removeClass('fa-angle-right')
                .addClass('fa-angle-down');
            list.show();
        } else {
            link.removeClass('active').find('.mobile-icon').first().removeClass('fa-angle-down')
                .addClass('fa-angle-right');
            list.hide();
        }
    }

    private initErrorNotifier() {
        const self: Main = this;
        $('.jsErrorNotification').each(function (index) {
            new Noty({
                text: $(this).text(),
                type: 'error',
                timeout: 10000,
            }).show();
        });
    }

    private showEnquireNowForm(url) {
        const self: Main = this;
        $.post(url, {
            action: 'getEnquireNowForm',
        }, (response) => {
            self.popup = $.popup({
                customContent: response.content,
                width: $(window).width() < 800 ? 300 : 800,
                customButtons: {},
                textAlign: 'left',
            });
        });
    }

    private showCatalogForm(url) {
        const self: Main = this;
        $.post(url, {
            action: 'getCatalogForm',
        }, (response) => {
            self.popup = $.popup({
                customContent: response.form,
                width: $(window).width() < 800 ? 300 : 800,
                customButtons: {},
            });
        });
    }

    private showNewsletterForm(url) {
        const self: Main = this;
        $.post(url, {
            action: 'getNewsletterForm',
        }, (response) => {
            self.popup = $.popup({
                customContent: response.form,
                width: $(window).width() < 800 ? 300 : 800,
                customButtons: {},
            });
        });
    }

    private openFlyout() {
        const self: Main = this;
        $.post('/requests-post', {
            action: 'getFlyout',
        }, (response) => {
            const flyout = new Noty({
                layout: 'centerRight',
                type: 'notification',
                closeWith: 'button',
                timeout: false,
                text: response.data,
                buttons: [
                    Noty.button($.trans('close'), 'flyout-close', () => {
                        localStorage.flyoutClosed = 1;
                        flyout.close();
                    }),
                    Noty.button($.trans('request'), 'flyout-send', () => {
                        localStorage.flyoutClosed = 1;
                        flyout.close();
                        window.open(flyoutURL, '_blank');
                    }, {id: 'button1', 'data-status': 'ok'}),
                ],
            });
            if (!localStorage.flyoutClosed) {
                flyout.show();
            }
        });
    }

    private openIncentiveDiscountPopup() {
        $.post(window.location.origin + '/openIncentiveDiscountPopup', (response) => {
            this.popup = $.popup({
                customButtons: false,
                customContent: response.content,
                width: $(window).width() >= 900 ? 800 : 300,
                mandatoryButtons: true,
            });
        });
    }

    private initSidebar() {
        $('#sidebar').simplerSidebar({
            toggler: '#toggleSidebar',
            quitter: '.jsCloseSidebar',
            top: 48,
            align: 'left',
        });

        $('#accountSidebar').simplerSidebar({
            toggler: '#accountMenu',
            top: 48,
            align: 'right',
        });

        $("#configuratorQuickstartSidebar").simplerSidebar({
            toggler: "#configuratorQuickstartMenu",
            top: 48,
            align: 'left',
        });

        $("#configuratorSidebar").simplerSidebar({
            toggler: "#configuratorMenu",
            top: 48,
            align: 'left',
        });
    }

    private initLanguagePicker() {
        $('.product-category-item-link').on('mouseenter', function (e) {
            $(this).prop('save-title', $(this).attr('title'));
            $(this).removeAttr('title');
        }).on('mouseleave click', function (e) {
            $(this).attr('title', $(this).prop('save-title'));
            $(this).removeProp('save-title');
        });

        $('.product-item-link').on('mouseenter', function (e) {
            $(this).prop('save-title', $(this).attr('title'));
            $(this).removeAttr('title');
        }).on('mouseleave click', function (e) {
            $(this).attr('title', $(this).prop('save-title'));
            $(this).removeProp('save-title');
        });
    }

    private initSearch() {
        const self = this;

        $(document).on('click', (e) => {
            if (self.tooltip && !$(e.target).hasClass('.tooltipster-box') && $(e.target).parents('.tooltipster-box').length === 0 && $(e.target).prop('id') !== 'search_form_search') {
                self.tooltip.tooltipster('destroy');
                self.tooltip = null;
            }
        });

        $(document).on('click', '.suggestion', function (e) {
            e.preventDefault();
            $('#search_form_search').val($(this).data('suggestion')).trigger('keyup');
        });

        $(document).on('keyup', '#mobile_search_search', (e) => {
            const $input = $(e.target);
            const value = String($input.val());
            this.search($input, value);
        });

        $(document).on('keyup', '#search_form_search', (e) => {
            const $input = $(e.target);
            const value = String($input.val());
            this.search($input, value);
        });
    }

    private search($target, value) {
        const self = this;
        if (value.length > 3) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                $.post('/search/autocorrect', {
                    action: 'autocorrect',
                    word: value,
                }, (response) => {
                    if (self.tooltip) {
                        self.tooltip.tooltipster('content', response.content);
                    } else {
                        // @ts-ignore
                        import('tooltipster/dist/js/tooltipster.bundle.min').then(() => {
                            this.tooltip = $target.tooltipster({
                                content: response.content,
                                side: 'bottom',
                                theme: 'tooltipster-shadow',
                                trigger: 'custom',
                                interactive: true,
                                contentAsHTML: true,
                                autoClose: true,
                            });
                            this.tooltip.tooltipster('open');
                            this.tooltip.tooltipster('option', 'updateAnimation', false);
                        });
                    }
                });
            }, 1000);
        } else if (self.tooltip) {
            self.tooltip.tooltipster('content', $.trans('Search found no exact matches.'));
        }
    }

    private initSlider() {
        $('.jsOverviewSlideshow').not('.slick-initialized').slick({
            slidesToShow: 5,
            arrows: true,
            dots: false,
            rows: 0,
            prevArrow: '<button class="slide-arrow slick-prev"></button>',
            nextArrow: '<button class="slide-arrow slick-next"></button>',
            responsive: [{
                breakpoint: 1600,
                settings: {
                    dots: true,
                    slidesToShow: 5,
                },
            }, {
                breakpoint: 1200,
                settings: {
                    dots: true,
                    slidesToShow: 3,
                },
            }, {
                breakpoint: 900,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                },
            }, {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                },
            }],
        });

        $('.configuration-steps').slick({
            slidesToShow: 3,
            slidesToScroll: 1,
            asNavFor: '.jsConfigurationTab',
            dots: false,
            centerMode: true,
            focusOnSelect: true,
            infinite: false,
            draggable: false,
        });

        $('.jsConfigurationTab').slick({
            slidesToShow: 1,
            arrows: false,
            dots: false,
            asNavFor: '.configuration-steps',
            infinite: false,
            draggable: false,
            adaptiveHeight: false,
        });

        $('a[data-slide]').on('click', function (e) {
            e.preventDefault();
            const slideno = $(this).data('slide');
            $('.slider-nav').slick('slickGoTo', slideno - 1);
        });

        $('.nav .prev').on('click', (e) => {
            e.preventDefault();
            return false;
        });

        $('.nav .next').on('click', (e) => {
            e.preventDefault();
            return false;
        });

        $('.jsFlipDots > div').on('click', function (e) {
            e.preventDefault();
            const newImgSrc = $(this).attr('data-src');
            const imgTag = $(this).parent().prev();
            $(this).parent().children().each(function () {
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                }
            });
            $(this).addClass('active');
            imgTag.attr('src', newImgSrc);
            return false;
        });

        $('.jsFlipImage').on('click', function (e) {
            e.preventDefault();
            let triggered = false;
            $(this).next('.jsFlipDots').children().each(function () {
                if (!triggered && !$(this).hasClass('active')) {
                    $(this).trigger('click');
                    triggered = true;
                }
            })
            return false;
        });

        $('.jsHighlightSlideshow').slick({
            slidesToShow: 5,
            prevArrow: '<i class="far fa-chevron-left arrow"></i>',
            nextArrow: '<i class="far fa-chevron-right arrow"></i>',
            lazyLoad: 'ondemand',
            rows: 0, // Disable grid mode
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $(document).on('click', '.jsAccessoryToCart', function (e: Event) {
            e.preventDefault();
            e.stopPropagation();
            CartFunctions.addToCart($(this), 1);
        });
    }

    private initBannerCountdown() {
        const $bannerCountdownContainer = $('#bannerCountdown');
        if ($bannerCountdownContainer.length > 0) {
            const targetDate = new Date($bannerCountdownContainer.data('target-date'));

            const countdownInterval = setInterval(() => {
                const now = new Date().getTime();
                const distance = targetDate.getTime() - now;

                if (distance <= 0) {
                    clearInterval(countdownInterval);
                    $bannerCountdownContainer.text('00:00:00:00');
                } else {
                    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    const formattedTime = `${this.pad(days, 2)}:${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`;

                    $bannerCountdownContainer.text(formattedTime);
                }
            }, 1000);
        }
    }

    private pad(number, length) {
        let str = String(number);
        while (str.length < length) {
            str = '0' + str;
        }
        return str;
    }
}

$(() => {
    new Main();
});
