/*!
 * @overview tooltip.js
 */
var pluginName = "tooltip",
    defaults = {
        alwaysOnCursor: true,
        fadeInSpeed: 300,
        fadeOutSpeed: 100,
        offsetX: 10,
        offsetY: 20,
        text: 'tooltip',
        width: ''
    };

function Plugin(element, options) {
    this.element = $(element);
    this.options = $.extend({}, defaults, options);

    this._defaults = defaults;
    this._name = pluginName;

    this.init();
}

Plugin.prototype = {
    init: function () {
        var self = this;
        self.placed = false;
        // Don't init plugin on mobile devices
        if (!navigator.userAgent.match(/Tablet|iPad|Mobile|Windows Phone|Lumia|Android|webOS|iPhone|iPod|Blackberry|PlayBook|BB10|Opera Mini|\bCrMo\/|Opera Mobi/i)) {
            self.initTooltip();
            self.initMouseListeners();
        }
    },

    initTooltip: function () {
        var self = this;
        self.tooltip = $('<div>').addClass('tooltip').css({width: self.options.width}).html(self.options.text);
    },

    setText: function (text) {
        var self = this;
        if (self.tooltip) {
            self.tooltip.html(text);
        }
    },

    placeTooltip: function (e) {
        var self = this;
        var left = e.clientX;
        if (self.options.offsetX == 'center') {
            left -= self.options.width / 2;
        } else {
            left += self.options.offsetX;
            if (left + self.tooltip.width() + 20 > $(window).width()) {
                left = $(window).width() - self.tooltip.width() - 20;
            }
        }

        var top = e.clientY + self.options.offsetY;
        left += $(window).scrollLeft();
        top += $(window).scrollTop();
        if (self.options.alwaysOnCursor || !self.placed) {
            self.tooltip.css({
                left: left,
                top: top,
                width: self.options.width
            });
            self.placed = true;
        } else {
            self.tooltip.stop(true, false).animate({
                left: left,
                top: top
            }, 100, 'swing');
        }
    },

    initMouseListeners: function () {
        var self = this;
        self.element.on('mousemove', function (e) {
            self.placeTooltip(e);
        });
        self.element.mouseenter(function (e) {
            if (self.tooltip.html() == '') return true;

            self.placeTooltip(e);
            $('.tooltip').remove();
            $('body').append(self.tooltip);
        }).mouseleave(function () {
            self.tooltip.remove();
            self.placed = false;
        });
    }
};

$.fn[pluginName] = function (options) {
    return this.each(function () {
        if (!$.data(this, pluginName)) {
            $.data(this, pluginName,
                new Plugin(this, options));
        }
    });
};